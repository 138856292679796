<template>
    <div id="top" class="page-wrapper">
        
        
        <section class="hero work" v-if="$route.meta.lang === 'en'">
            <div class="hero-wrap max-padding" data-scroll data-scroll-speed="-2">
                <h2 class="small-title">NotCo</h2>
                <h1 class="big-title">You won't believe it's NotMilk</h1>
            </div>
            <hero-svg-cut-light />
        </section>

        <section class="hero work" v-if="$route.meta.lang === 'fr'">
            <div class="hero-wrap max-padding" data-scroll data-scroll-speed="-2">
                <h2 class="small-title">NotCo</h2>
                <h1 class="big-title">Ceci n'est pas une pub de lait - NotMilk</h1>
            </div>
            <hero-svg-cut-light />
        </section>



        <section class="work-content" v-if="$route.meta.lang === 'en'">
          <div class="img-wrap">
            <img src="@/assets/img/notco-hero.jpg" alt="Gardasil">
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Context</h3>
            </div>
            <div class="right">
              <p class="regular-text">NotMilk is the next generation of plant-based milk. Developed using a mix of artificial intelligence, machine learning and botanical components. This Chilean product's Canadian launch, was within a very competitive category, during a pandemic context, with many unknown variables. The solution was to dominate media abandoned during the pandemic, using mass and mobile impact formats, to create a crucial effect in major cities. The use of transit vehicle wraps with clear messaging and contrasting colors helped us stand out. These long-running media tactics generated a lasting impression in the consumer's mind and stimulated new consumer habits.</p>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Goals</h3>
            </div>
            <div class="right">
              <ul class="list-wrap">
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Succesfully introduce NotMilk in the Canadian market</p>
                </li>
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Build brand awareness by demonstrating its benefits and superiority to milk</p>
                </li>
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Build brand equity to prepare for upcoming NotCo launches</p>
                </li>
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Generate Sales and loyalty to flexitarian consumers</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Strategy</h3>
            </div>
            <div class="right">
              <p class="regular-text">To complement the mass campaign, geo-targeted online and offline point-of-sale media were deployed to drive sales and reach consumers in strategic locations. These media were selected and shaped through data sources to identify retailer sales areas and reach a target audience looking for plant-based milk options. Ensuring we were present in high-traffic areas (busy highways) and digitally (pocket screens, connected TV screens, and computer screens leveraging top sites and social media). Some in-store and transit activations were also conducted to drive sales and create campaign momentum, using sample and coupon distribution. Content creation was leveraged to tell a story to consumers looking for new plant-based milk options.</p>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Results</h3>
            </div>
            <div class="right">
              <div class="stats-wrap">
                <div class="stat">
                  <p class="number">34%</p>
                  <p class="regular-text">assisted brand awareness</p>
                </div>
                <div class="stat">
                  <p class="number">50%</p>
                  <p class="regular-text">of Canadians reached were interested in purchasing NotMilk post-campaign</p>
                </div>
                <div class="stat">
                  <p class="number">+1.2M</p>
                  <p class="regular-text">reached every month on NotMilk's social media accounts</p>
                </div>
                <div class="stat">
                  <p class="number">1/4</p>
                  <p class="regular-text">Canadians exposed to our ads purchased the product</p>
                </div>
              </div>
            </div>
          </div>

          <div class="media-wrap">
            <div class="photos-wrap full">
              <img src="@/assets/img/notco-img-1.jpg" alt="NotCo">
            </div>
            <div class="photos-wrap">
              <img src="@/assets/img/notco-phone.jpg" alt="NotCo">
              <div class="phone-player" data-item="video">
                  <img src="@/assets/img/iphone-frame.png" class="iphone-frame" alt="iphone">
                  <video autoplay muted loop playsinline>
                    <source src="@/assets/video/phone.mp4" type="video/mp4">
                  </video>
              </div>
            </div>
            <div class="photos-wrap full">
              <img src="@/assets/img/notco-img-2.jpg" alt="NotCo">
            </div>
          </div>

          <work-svg-cut />
        </section>

        <section class="work-content" v-if="$route.meta.lang === 'fr'">
          <div class="img-wrap">
            <img src="@/assets/img/notco-hero.jpg" alt="Gardasil">
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Contexte</h3>
            </div>
            <div class="right">
              <p class="regular-text">NotMilk c’est la nouvelle génération de lait à base de plantes. Développée à partir de la technologie d’intelligence artificielle, ce produit apparaissait dans une catégorie très compétitive, dans un contexte pandémique difficile, avec des variables inconnues. La solution fut de dominer des médias abandonnés en temps de pandémie, utilisant des formats d'impact de masse et mobile, pour créer un effet majeur dans les villes ciblées. L'utilisation d’habillage complet de véhicules de transport en commun avec des messages clairs et des couleurs contrastantes ont permis de se démarquer. Ces médias de longue durée ont permis de générer une impression durable dans l’esprit du consommateur et de stimuler de nouvelles habitudes de consommation.</p>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Objectifs</h3>
            </div>
            <div class="right">
              <ul class="list-wrap">
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Faire connaître NotMilk aux canadiens.</p>
                </li>
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Faire connaître la marque en démontrant ses avantages et sa supériorité par rapport au lait</p>
                </li>
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Renforcer le capital de la marque pour préparer les prochains lancements de NotCo.</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Stratégie</h3>
            </div>
            <div class="right">
              <p class="regular-text">Pour compléter la campagne de masse, des médias pointus en ligne et hors lignes géo-ciblés furent déployés, pour canaliser les ventes et rejoindre les consommateurs aux endroits stratégiques. Ces médias  ont été sélectionnés et façonnés grâce à l'utilisation de 49 sources de données pour identifier les secteurs propices à la vente chez les détaillants et rejoignant une cible à la recherche d'options de lait à base de plantes. Certaines activations ont également eu lieu en magasin et sur les circuits de transport en commun pour stimuler les ventes et profiter du momentum de campagne, à l'aide de distribution d'échantillons et de coupons rabais. La création de contenu fut également utilisée pour raconter une histoire au consommateur a la recherche de nouvelles solutions de lait végétal.</p>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Résultats</h3>
            </div>
            <div class="right">
              <div class="stats-wrap">
                <div class="stat">
                  <p class="number">34%</p>
                  <p class="regular-text">notoriété assistée</p>
                </div>
                <div class="stat">
                  <p class="number">50%</p>
                  <p class="regular-text">des Canadiens atteints étaient intéressés d'effectuer l'achat de NotMilk après la campagne publicitaire</p>
                </div>
                <div class="stat">
                  <p class="number">+1.2M</p>
                  <p class="regular-text">rejoints chaque mois sur les réseaux sociaux de NotMilk</p>
                </div>
                <div class="stat">
                  <p class="number">1/4</p>
                  <p class="regular-text">de tous les Canadiens exposés à la campagne, a fait l'achat du produit</p>
                </div>
              </div>
            </div>
          </div>

          <div class="media-wrap">
            <div class="photos-wrap full">
              <img src="@/assets/img/notco-img-1.jpg" alt="NotCo">
            </div>
            <div class="photos-wrap">
              <img src="@/assets/img/notco-phone.jpg" alt="NotCo">
              <div class="phone-player" data-item="video">
                  <img src="@/assets/img/iphone-frame.png" class="iphone-frame" alt="iphone">
                  <video autoplay muted loop playsinline>
                    <source src="@/assets/video/phone.mp4" type="video/mp4">
                  </video>
              </div>
            </div>
            <div class="photos-wrap full">
              <img src="@/assets/img/notco-img-2.jpg" alt="NotCo">
            </div>
          </div>

          <work-svg-cut />
        </section>


        <section class="other-work" v-if="$route.meta.lang === 'en'">
          <h4 class="small-title">Other work</h4>
          <div class="blocs">
            <a href="/instacart" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="300">
              <div class="img">
                <img src="@/assets/img/instacart-logo.svg" class="logo" alt="Instacart logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/instacart-img.jpg" class="cover" alt="Instacart">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("Instacart") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
            <a href="/gardasil" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="400">
              <div class="img">
                <img src="@/assets/img/gardasil-logo.png" class="logo" alt="Gardasil logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/gardasil-img.jpg" class="cover" alt="Gardasil 9">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("Gardasil 9") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
          </div>
        </section>

        <section class="other-work" v-if="$route.meta.lang === 'fr'">
          <h4 class="small-title">Autres projets</h4>
          <div class="blocs">
            <a href="/fr/instacart" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="300">
              <div class="img">
                <img src="@/assets/img/instacart-logo.svg" class="logo" alt="Instacart logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/instacart-img.jpg" class="cover" alt="Instacart">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("Instacart") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
            <a href="/fr/gardasil" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="400">
              <div class="img">
                <img src="@/assets/img/gardasil-logo.png" class="logo" alt="Gardasil logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/gardasil-img.jpg" class="cover" alt="Gardasil 9">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("Gardasil 9") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
          </div>
        </section>

    </div>
</template>

<script>


import HeroSvgCutLight from '@/components/svg-cut/HeroSvgCutLight.vue'
import WorkSvgCut from '@/components/svg-cut/WorkSvgCut.vue'

import { initLocoScroll } from '@/plugins/LocoScroll'
import { loadAssets } from '@/plugins/AssetsLoader'

export default {
    name: 'Work',

    components: {
        HeroSvgCutLight,
        WorkSvgCut
    },

    data() {
      return {}
    },

    mounted() {
        const scroll = initLocoScroll()

        // Page load buffer
        setTimeout(() => {
            scroll.update()

            setTimeout(() => {
                scroll.update()
            }, 3155)
        }, 755)

        // ReUpdate LocoScroll after assets load
        loadAssets('[data-scroll-container]', () => {
            scroll.update()
        })
    },
}
</script>
