<template>
    <div class="svg-wrap">
        <div class="hero-svg-cut circles circles-back" data-scroll data-scroll-speed="-1">
            <svg class="inner-svg" viewBox="0 0 1920 1458" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="1036" cy="337" r="95" fill="#F7B8BA" />
                <circle cx="234" cy="316" r="44" fill="#F7B8BA" />
                <circle cx="1765.5" cy="333.5" r="150.5" fill="#6216EA" />
            </svg>
        </div>
        <svg class="hero-svg-cut waves" viewBox="0 0 1920 1458" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1797.16 1113.76C1748.63 1122.58 1746.08 1180.63 1746.08 1180.63C1746.08 1180.63 1748.32 1239.44 1721.21 1246.41C1694.1 1253.38 1664.42 1252.93 1612.98 1207.82C1561.54 1162.71 1469.42 1083.2 1389.75 1085.22C1310.07 1087.25 1280.09 1170.42 1277.97 1176.15C1275.85 1181.89 1239.89 1320.59 1236.75 1341.28C1235.19 1349.86 1232.47 1358.21 1228.65 1366.19L1101.01 1346.82C1098.48 1340.94 1096.03 1334.85 1093.57 1328.63C1077.43 1287.87 1036.24 1183.38 951.139 1180.28C884.319 1178.92 886.295 1231.67 886.295 1231.67L873.974 1309.44C873.966 1310.41 873.87 1311.38 873.686 1312.33L-233.266 1144.39L-124.413 426.919C-108.005 424.532 -86.2215 428.518 -60.3566 449.66C0.336091 499.186 -4.93404 517.547 31.5434 497.873C68.0209 478.199 57.9232 438.357 57.9232 438.357C57.9232 438.357 52.2238 386.082 95.6962 385.625C139.169 385.168 175.001 426.928 175.001 426.928C175.001 426.928 246.187 505.274 293.249 473.101C340.312 440.927 308.076 391.657 308.076 391.657C308.076 391.657 279.938 331.938 334.756 322.11C390.323 307.352 434.591 326.165 434.591 326.165C434.591 326.165 512.84 374.337 545.173 324.797C577.507 275.257 639.861 248.417 643.498 248.968C647.134 249.52 701.335 235.615 726.328 307.924C751.321 380.233 731.047 472.969 731.047 472.969C731.047 472.969 728.426 531.045 788.412 528.05C848.398 525.054 892.498 463.194 892.498 463.194C892.498 463.194 995.043 318.652 1085.26 312.576C1175.47 306.5 1225.82 375.604 1225.82 375.604C1225.82 375.604 1291.75 464.245 1362.13 441.658C1369.39 439.335 1376.33 436.471 1382.88 433.1L1382.67 433.068C1382.67 433.068 1432.59 411.628 1433.57 352.822C1434.54 294.016 1486.76 276.965 1511.54 296.86C1536.32 316.756 1558.95 357.315 1606.59 350.829C1654.23 344.342 1700.07 317.308 1710.35 301.918C1716.3 293.011 1743.11 274.024 1768.6 277.78L1768.97 277.835C1787.35 280.703 1805.01 295.5 1813.61 334.534C1833.37 424.328 1913.97 430.863 1983.74 444.875L1877.07 1148.01C1853.59 1128.59 1822.72 1109.13 1797.16 1113.76Z" fill="#fff"/>
        </svg>
        <div class="hero-svg-cut circles circles-front" data-scroll data-scroll-speed="2">
            <svg class="inner-svg" viewBox="0 0 1920 1458" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="653" cy="299" r="57" fill="#6216EA" />
                
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'hero-svg-cut'
}
</script>

<style lang="scss" scoped>
    .svg-wrap {
        position: relative;
        margin-top: -230px;
        margin-bottom: -50vw;

        @media (min-width: 1445px) {
            margin-top: -125px;
        }

        @media (min-height: 745px) {
            margin-top: -275px;
        }

        @media (min-height: 899px) {
            margin-top: -125px;
        }

        @media (max-width: 767px) {
            margin-top: -300px;
        }

        .hero-svg-cut {
            position: relative;
            display: block;
            width: 105%;
            z-index: 5;
            background: transparent;

            .inner-svg {
                width: 100%;

                @media (max-width: 767px) {
                    width: 82%;
                }
            }
        }

        .circles {
            position: absolute;
            top: 0;
            left: 0;
        }

        .circles-back {
            top: 45px;

            @media (max-width: 767px) {
                top: 25px;
            }
        }

        .circles-front {
            top: -75px;

            @media (max-width: 1024px) {
                top: -30px;
            }

            @media (max-width: 767px) {
                width: 135%;
            }
        }
    }
</style>
