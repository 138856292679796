<template>
    <div id="top" class="page-wrapper">
        
        
        <section class="hero work" v-if="$route.meta.lang === 'en'">
            <div class="hero-wrap max-padding" data-scroll data-scroll-speed="-2">
                <h2 class="small-title">Instacart</h2>
                <h1 class="big-title">Life is short, get your groceries delivered.</h1>
            </div>
            <hero-svg-cut-light />
        </section>

        <section class="hero work" v-if="$route.meta.lang === 'fr'">
            <div class="hero-wrap max-padding" data-scroll data-scroll-speed="-2">
                <h2 class="small-title">Instacart</h2>
                <h1 class="big-title">La vie est courte, faites l'épicerie en ligne</h1>
            </div>
            <hero-svg-cut-light />
        </section>



        <section class="work-content" v-if="$route.meta.lang === 'en'">
          <div class="img-wrap">
            <img src="@/assets/img/instacart-hero.jpg" alt="Instacart">
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Context</h3>
            </div>
            <div class="right">
              <p class="regular-text">Launching in a French-speaking market was a major challenge for Instacart as brand awareness was almost inexistent in Quebec.  The company was looking to increase sales for its big box retailers; Costco, Wal-Mart and Metro among others, to deliver top business results.</p>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Goals</h3>
            </div>
            <div class="right">
              <ul class="list-wrap">
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">To make Instacart recognized by Quebecers.</p>
                </li>
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Educate Quebecers on the advantages of shopping with Instacart.</p>
                </li>
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Make Instacart a mindful reference for shopping, especially for shopping at Walmart, Costco, and Metro.</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Strategy</h3>
            </div>
            <div class="right">
              <p class="regular-text">We wanted to reach the Quebec audience with an impact in their day to day life routines. This meant being present on main arteries with OOH billboards, getting public trans wrapped, and leveraging digital inventory and publishers to live within Quebecer ecosystems. Impactful out-of-home presence found on different modes of transport in all markets dominated the landscape and offered key messages that suggested the transit user was shopping for groceries.<br><br>Other visuals stimulated immediate trial using QR codes.  Geo-targeting of key areas around Costco Wal-Mart and Metro retail users allowed for target reach in the participating retailers' target areas. Digital message delivery was weather-triggered. Ads of Deliver sunshine! were executed a sunny day and Deliver comfort! on a dark day.<br><br>Cross-platform content integrations were played to demonstrate the platform in real time. Key flyer viewing times, Wednesdays/Thursdays, were strategically selected to drive adoption and create habit among the target audience.</p>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Results</h3>
            </div>
            <div class="right">
              <div class="stats-wrap">
                <div class="stat">
                  <p class="number">+100%</p>
                  <p class="regular-text">doubled awareness post-campaign</p>
                </div>
                <div class="stat">
                  <p class="number">59%</p>
                  <p class="regular-text">of users reached are a lot more likely to use Instacart</p>
                </div>
                <div class="stat">
                  <p class="number">7.65 M</p>
                  <p class="regular-text">of Quebecers were reached throughout this campaign</p>
                </div>
                <div class="stat">
                  <p class="number">2.87x</p>
                  <p class="regular-text">above average for the click through rate throughout the campaign</p>
                </div>
              </div>
            </div>
          </div>

          <div class="media-wrap">
            <div class="video-player" data-item="video">
                <video autoplay muted loop playsinline class="video-1">
                    <source src="@/assets/video/instacart-video.mp4" type="video/mp4">
                </video>
                <img src="@/assets/img/tv-frame.png" class="video" alt="Connected TV">
            </div>
            <div class="video-wrap">
              <video autoplay muted loop playsinline class="video-1">
                <source src="@/assets/video/instacart-video-2.mp4" type="video/mp4">
              </video>
            </div>
            <div class="photos-wrap">
              <img src="@/assets/img/instacart-phone-1.jpg" alt="Instacart">
              <img src="@/assets/img/instacart-phone-2.jpg" alt="Instacart">
              <img src="@/assets/img/instacart-bus.jpg" alt="Instacart">
            </div>
          </div>

          <work-svg-cut />
        </section>


        <section class="work-content" v-if="$route.meta.lang === 'fr'">
          <div class="img-wrap">
            <img src="@/assets/img/instacart-hero.jpg" alt="Instacart">
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Contexte</h3>
            </div>
            <div class="right">
              <p class="regular-text">La notoriété de la marque Instacart était pratiquement inexistante au Québec et lancer dans un marché francophone était un défi de taille pour cette compagnie américaine. L'entreprise cherchait à augmenter les ventes de ses détaillants à grande surface, notamment Costco, Wal-Mart et Metro, afin d'obtenir des meilleurs résultats d'affaires.</p>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Objectifs</h3>
            </div>
            <div class="right">
              <ul class="list-wrap">
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Faire connaître Instacart aux Québécois.</p>
                </li>
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Éduquer les Québécois sur les avantages de magasiner avec Instacart.</p>
                </li>
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Faire d'Instacart la référence pour faire du shopping, en particulier pour les achats chez Walmart Costco Metro</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Stratégie</h3>
            </div>
            <div class="right">
              <p class="regular-text">L’affichage d'impact sur les modes de transportations dans tous les marchés dominaient le paysage et proposaient des messages clés qui suggéraient que l’utilisateur du transport en commun était en train de faire l'épicerie. Le géo-ciblage des secteurs clés autour des utilisateurs des détaillants Costco Wal-Mart et Métro permettaient de rejoindre la cible dans les secteurs ciblés par les détaillants participants. La diffusion des messages numériques était adaptée à la météo. Faites-vous livrer du soleil! lors de journée ensoleillée et Faites-vous livrer du réconfort! lors de journées sombres.<br><br>Des intégrations de contenu multiplateformes étaient diffusées pour faire la démonstration en temps réel de la plateforme. Les moment clés de consultation des circulaires, soient les mercredis/jeudis étaient stratégiquement sélectionnés pour favoriser l’adoption et créer l’habitude auprès de la cible.</p>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Résultats</h3>
            </div>
            <div class="right">
              <div class="stats-wrap">
                <div class="stat">
                  <p class="number">+100%</p>
                  <p class="regular-text">double la notoriété de la marque après la campagne</p>
                </div>
                <div class="stat">
                  <p class="number">59%</p>
                  <p class="regular-text">des utilisateurs atteints sont beaucoup plus susceptibles d'utiliser Instacart</p>
                </div>
                <div class="stat">
                  <p class="number">7.65 M</p>
                  <p class="regular-text">de Québécois ont été atteints tout au long de cette campagne</p>
                </div>
                <div class="stat">
                  <p class="number">2.87x</p>
                  <p class="regular-text">supérieur à la moyenne d'un taux de clics tout au long de la campagne</p>
                </div>
              </div>
            </div>
          </div>

          <div class="media-wrap">
            <div class="video-player" data-item="video">
                <video autoplay muted loop playsinline class="video-1">
                    <source src="@/assets/video/instacart-video.mp4" type="video/mp4">
                </video>
                <img src="@/assets/img/tv-frame.png" class="video" alt="Connected TV">
            </div>
            <div class="video-wrap">
              <video autoplay muted loop playsinline class="video-1">
                <source src="@/assets/video/instacart-video-2.mp4" type="video/mp4">
              </video>
            </div>
            <div class="photos-wrap">
              <img src="@/assets/img/instacart-phone-1.jpg" alt="Instacart">
              <img src="@/assets/img/instacart-phone-2.jpg" alt="Instacart">
              <img src="@/assets/img/instacart-bus.jpg" alt="Instacart">
            </div>
          </div>

          <work-svg-cut />
        </section>



        <section class="other-work" v-if="$route.meta.lang === 'en'">
          <h4 class="small-title">Other work</h4>
          <div class="blocs">
            <a href="/gardasil" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="300">
              <div class="img">
                <img src="@/assets/img/gardasil-logo.png" class="logo" alt="Gardasil logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/gardasil-img.jpg" class="cover" alt="Gardasil 9">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("Gardasil 9") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
            <a href="/notco" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="400">
              <div class="img">
                <img src="@/assets/img/logos/not-co.png" class="logo" alt="NotCo logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/work-3.jpg" class="cover" alt="Media Innovation">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("NotCo") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
          </div>
        </section>

        <section class="other-work" v-if="$route.meta.lang === 'fr'">
          <h4 class="small-title">Autres projets</h4>
          <div class="blocs">
            <a href="/fr/gardasil" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="300">
              <div class="img">
                <img src="@/assets/img/gardasil-logo.png" class="logo" alt="Gardasil logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/gardasil-img.jpg" class="cover" alt="Gardasil 9">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("Gardasil 9") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
            <a href="/fr/notco" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="400">
              <div class="img">
                <img src="@/assets/img/logos/not-co.png" class="logo" alt="NotCo logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/work-3.jpg" class="cover" alt="Media Innovation">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("NotCo") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
          </div>
        </section>

    </div>
</template>

<script>


import HeroSvgCutLight from '@/components/svg-cut/HeroSvgCutLight.vue'
import WorkSvgCut from '@/components/svg-cut/WorkSvgCut.vue'

import { initLocoScroll } from '@/plugins/LocoScroll'
import { loadAssets } from '@/plugins/AssetsLoader'

export default {
    name: 'Work',

    components: {
        HeroSvgCutLight,
        WorkSvgCut
    },

    data() {
      return {}
    },

    mounted() {
        const scroll = initLocoScroll()

        // Page load buffer
        setTimeout(() => {
            scroll.update()

            setTimeout(() => {
                scroll.update()
            }, 3155)
        }, 755)

        // ReUpdate LocoScroll after assets load
        loadAssets('[data-scroll-container]', () => {
            scroll.update()
        })
    },
}
</script>
