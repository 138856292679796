<template>
  <section>
    <div class="svg-graph desktop-svg-graph">
      <!-- PH -->
      <svg class="graph graph-ph" width="1060" height="310" viewBox="0 0 1060 310" fill="none" xmlns="http://www.w3.org/2000/svg">
          <!-- Line BG no-filler -->
          <path d="M244.953 23.1035C-62.9701 23.1035 -54.9721 289.991 244.953 289.991C544.878 289.991 540.879 23.1035 836.805 23.1035C1132.73 23.1035 1130.73 289.991 836.805 289.991C645.645 289.991 406 23.1035 337.5 23.1035" stroke="#373458" stroke-width="3" />
      </svg>
      <!-- BG -->
      <svg class="graph graph-bg" width="1060" height="310" viewBox="0 0 1060 310" fill="none" xmlns="http://www.w3.org/2000/svg">
          <!-- Line BG no-filler -->
          <path d="M244.953 23.1035C-62.9701 23.1035 -54.9721 289.991 244.953 289.991C544.878 289.991 540.879 23.1035 836.805 23.1035C1132.73 23.1035 1130.73 289.991 836.805 289.991C645.645 289.991 406 23.1035 337.5 23.1035" stroke="#373458" stroke-width="3" />
      </svg>
      <!-- Filler -->
      <svg class="graph graph-filler" width="1060" height="310" viewBox="0 0 1060 310" fill="none" xmlns="http://www.w3.org/2000/svg">
          <!-- Line BG no-filler -->
          <path d="M244.953 23.1035C-62.9701 23.1035 -54.9721 289.991 244.953 289.991C544.878 289.991 540.879 23.1035 836.805 23.1035C1132.73 23.1035 1130.73 289.991 836.805 289.991C645.645 289.991 406 23.1035 337.5 23.1035" stroke="#6216EA" stroke-width="3" pathLength="1"/>
      </svg>
      <svg class="graph graph-steps" width="1060" height="310" viewBox="0 0 1060 310" fill="none" xmlns="http://www.w3.org/2000/svg">
          <!-- Arrows en of lines -->
          <path id="end" d="M344.197 30.3337L335.852 23.27L344.198 17.0004" stroke-width="3"/>

          <!-- Extra line ender -->
          <path id="end-arrow" d="M642.224 97.3536L654.244 96.7571L655.5 106.099" stroke-width="3"/>
          <path id="end-line" d="M673 240.763C591.5 191.129 636.333 110.71 653 97.9619" :class="{ 'is-active': graphStepImmediate >= 4 }" stroke-width="3"/>

          <!-- Steps -->
          <circle class="circle" id="i1" :class="{ 'is-active': graphStep >= 0 }" cx="260" cy="23" r="20" @click="graphGoTo(0)" />
          <circle class="circle" id="i2" :class="{ 'is-active': graphStep >= 1 }" cx="260" cy="290" r="20" @click="graphGoTo(1)"/>
          <circle class="circle" id="i3" :class="{ 'is-active': graphStep >= 2 }" cx="840" cy="23" r="20" @click="graphGoTo(2)" />
          <circle class="circle" id="i4" :class="{ 'is-active': graphStep >= 3 }" cx="840" cy="290" r="20" @click="graphGoTo(3)" />

          <!-- 1 -->
          <path class="number" d="M258.86 31V19.4L256.6 19.92V18.36L259.7 17H261.06V31H258.86Z" fill="white" @click="graphGoTo(0)" />
          <!-- 2 -->
          <path class="number" d="M255.14 297V295.48C256.047 294.76 256.913 294.033 257.74 293.3C258.567 292.567 259.3 291.847 259.94 291.14C260.593 290.42 261.107 289.72 261.48 289.04C261.853 288.347 262.04 287.68 262.04 287.04C262.04 286.613 261.967 286.213 261.82 285.84C261.673 285.453 261.433 285.147 261.1 284.92C260.767 284.68 260.32 284.56 259.76 284.56C258.92 284.56 258.287 284.827 257.86 285.36C257.447 285.893 257.247 286.533 257.26 287.28H255.2C255.213 286.293 255.42 285.467 255.82 284.8C256.233 284.133 256.78 283.627 257.46 283.28C258.153 282.933 258.933 282.76 259.8 282.76C261.12 282.76 262.173 283.133 262.96 283.88C263.76 284.613 264.16 285.64 264.16 286.96C264.16 287.747 263.967 288.527 263.58 289.3C263.207 290.073 262.713 290.82 262.1 291.54C261.5 292.247 260.853 292.913 260.16 293.54C259.467 294.167 258.807 294.733 258.18 295.24H264.58V297H255.14Z" fill="white" @click="graphGoTo(1)"/>
          <!-- 3 -->
          <path class="number" d="M839.9 30.24C838.993 30.24 838.167 30.08 837.42 29.76C836.687 29.4267 836.1 28.9267 835.66 28.26C835.22 27.5933 834.987 26.76 834.96 25.76H837.06C837.087 26.5067 837.34 27.14 837.82 27.66C838.313 28.1667 839.007 28.42 839.9 28.42C840.753 28.42 841.407 28.1867 841.86 27.72C842.313 27.24 842.54 26.6467 842.54 25.94C842.54 25.0867 842.233 24.4533 841.62 24.04C841.02 23.6267 840.28 23.42 839.4 23.42H838.36V21.66H839.42C840.207 21.66 840.84 21.4733 841.32 21.1C841.813 20.7267 842.06 20.2 842.06 19.52C842.06 18.9467 841.867 18.48 841.48 18.12C841.107 17.76 840.573 17.58 839.88 17.58C839.16 17.58 838.593 17.7933 838.18 18.22C837.767 18.6333 837.54 19.1533 837.5 19.78H835.4C835.427 18.9667 835.627 18.26 836 17.66C836.387 17.06 836.913 16.5933 837.58 16.26C838.247 15.9267 839.013 15.76 839.88 15.76C840.813 15.76 841.593 15.9267 842.22 16.26C842.86 16.58 843.34 17.02 843.66 17.58C843.993 18.1267 844.16 18.7267 844.16 19.38C844.16 20.1267 843.953 20.78 843.54 21.34C843.127 21.9 842.573 22.2733 841.88 22.46C842.68 22.6333 843.34 23.0267 843.86 23.64C844.38 24.24 844.64 25.02 844.64 25.98C844.64 26.7533 844.46 27.4667 844.1 28.12C843.74 28.76 843.207 29.2733 842.5 29.66C841.793 30.0467 840.927 30.24 839.9 30.24Z" fill="white" @click="graphGoTo(2)"/>
          <!-- 4 -->
          <path class="number" d="M841.8 297V294.2H834.78V292.52L841.46 283H843.9V292.36H845.86V294.2H843.9V297H841.8ZM837.08 292.36H841.92V285.34L837.08 292.36Z" fill="white" @click="graphGoTo(3)"/>
      </svg>
    </div>
    <div class="svg-graph mobile-svg-graph">
        <!-- PH -->
        <svg class="graph graph-ph" width="1049" height="307" viewBox="0 0 1049 307" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M231.953 20.1035C-75.9701 20.1035 -67.9721 286.991 231.953 286.991C531.878 286.991 527.879 20.1035 823.805 20.1035C1119.73 20.1035 1117.73 286.991 823.805 286.991C632.645 286.991 393 20.1035 324.5 20.1035" stroke="#373458" stroke-width="8"/>
            <path d="M334.012 33.3575L318.952 20.6106L334.013 9.29656" stroke="#373458" stroke-width="6"/>
            <path d="M660 237.763C578.5 188.129 623.333 107.71 640 94.9619" stroke="#6216EA" stroke-width="8"/>
            <path d="M620.591 94.0857L642.667 92.9901L644.973 110.147" stroke="#6216EA" stroke-width="6"/>
            <circle cx="827" cy="287" r="20" fill="#373458"/>
            <path d="M828.8 294V291.2H821.78V289.52L828.46 280H830.9V289.36H832.86V291.2H830.9V294H828.8ZM824.08 289.36H828.92V282.34L824.08 289.36Z" fill="white"/>
            <circle cx="827" cy="20" r="20" fill="#373458"/>
            <path d="M826.9 27.24C825.993 27.24 825.167 27.08 824.42 26.76C823.687 26.4267 823.1 25.9267 822.66 25.26C822.22 24.5933 821.987 23.76 821.96 22.76H824.06C824.087 23.5067 824.34 24.14 824.82 24.66C825.313 25.1667 826.007 25.42 826.9 25.42C827.753 25.42 828.407 25.1867 828.86 24.72C829.313 24.24 829.54 23.6467 829.54 22.94C829.54 22.0867 829.233 21.4533 828.62 21.04C828.02 20.6267 827.28 20.42 826.4 20.42H825.36V18.66H826.42C827.207 18.66 827.84 18.4733 828.32 18.1C828.813 17.7267 829.06 17.2 829.06 16.52C829.06 15.9467 828.867 15.48 828.48 15.12C828.107 14.76 827.573 14.58 826.88 14.58C826.16 14.58 825.593 14.7933 825.18 15.22C824.767 15.6333 824.54 16.1533 824.5 16.78H822.4C822.427 15.9667 822.627 15.26 823 14.66C823.387 14.06 823.913 13.5933 824.58 13.26C825.247 12.9267 826.013 12.76 826.88 12.76C827.813 12.76 828.593 12.9267 829.22 13.26C829.86 13.58 830.34 14.02 830.66 14.58C830.993 15.1267 831.16 15.7267 831.16 16.38C831.16 17.1267 830.953 17.78 830.54 18.34C830.127 18.9 829.573 19.2733 828.88 19.46C829.68 19.6333 830.34 20.0267 830.86 20.64C831.38 21.24 831.64 22.02 831.64 22.98C831.64 23.7533 831.46 24.4667 831.1 25.12C830.74 25.76 830.207 26.2733 829.5 26.66C828.793 27.0467 827.927 27.24 826.9 27.24Z" fill="white"/>
            <circle cx="247" cy="287" r="20" fill="#6216EA"/>
            <path d="M242.14 294V292.48C243.047 291.76 243.913 291.033 244.74 290.3C245.567 289.567 246.3 288.847 246.94 288.14C247.593 287.42 248.107 286.72 248.48 286.04C248.853 285.347 249.04 284.68 249.04 284.04C249.04 283.613 248.967 283.213 248.82 282.84C248.673 282.453 248.433 282.147 248.1 281.92C247.767 281.68 247.32 281.56 246.76 281.56C245.92 281.56 245.287 281.827 244.86 282.36C244.447 282.893 244.247 283.533 244.26 284.28H242.2C242.213 283.293 242.42 282.467 242.82 281.8C243.233 281.133 243.78 280.627 244.46 280.28C245.153 279.933 245.933 279.76 246.8 279.76C248.12 279.76 249.173 280.133 249.96 280.88C250.76 281.613 251.16 282.64 251.16 283.96C251.16 284.747 250.967 285.527 250.58 286.3C250.207 287.073 249.713 287.82 249.1 288.54C248.5 289.247 247.853 289.913 247.16 290.54C246.467 291.167 245.807 291.733 245.18 292.24H251.58V294H242.14Z" fill="white"/>
            <circle cx="247" cy="20" r="20" fill="#6216EA"/>
            <path d="M245.86 28V16.4L243.6 16.92V15.36L246.7 14H248.06V28H245.86Z" fill="white"/>
        </svg>
        <!-- BG -->
        <svg class="graph graph-bg" width="1049" height="307" viewBox="0 0 1049 307" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M231.953 20.1035C-75.9701 20.1035 -67.9721 286.991 231.953 286.991C531.878 286.991 527.879 20.1035 823.805 20.1035C1119.73 20.1035 1117.73 286.991 823.805 286.991C632.645 286.991 393 20.1035 324.5 20.1035" stroke="#373458" stroke-width="8"/>
        </svg>
        <!-- Filler -->
        <svg class="graph graph-filler" width="1049" height="307" viewBox="0 0 1049 307" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M231.953 20.1035C-75.9701 20.1035 -67.9721 286.991 231.953 286.991C531.878 286.991 527.879 20.1035 823.805 20.1035C1119.73 20.1035 1117.73 286.991 823.805 286.991C632.645 286.991 393 20.1035 324.5 20.1035" stroke-width="8" stroke="#6216EA" pathLength="1"/>
        </svg>
        <!-- Steps -->
        <svg class="graph graph-steps graph-steps-mobile" width="1049" height="337" viewBox="0 0 1049 337" fill="none" xmlns="http://www.w3.org/2000/svg">

          <path class="end" d="M334.012 48.3575L318.952 35.6106L334.013 24.2966" stroke="#373458" stroke-width="6"/>
          <path class="end-line" d="M660 252.763C578.5 203.129 623.333 122.71 640 109.962" stroke="#6216EA" stroke-width="8"/>
          <path class="end-arrow" d="M620.591 109.086L642.667 107.99L644.973 125.147" stroke="#6216EA" stroke-width="6"/>

          <circle class="circle" :class="{ 'is-active': graphStep >= 3 }" cx="827" cy="302" r="35" fill="#373458" @click="graphGoTo(3)"/>
          <path class="number" d="M830.038 314V309.2H818V306.32L829.455 290H833.639V306.046H837V309.2H833.639V314H830.038ZM821.944 306.046H830.244V294.011L821.944 306.046Z" fill="white" @click="graphGoTo(3)"/>

          <circle class="circle" :class="{ 'is-active': graphStep >= 2 }" cx="827" cy="35" r="35" fill="#373458" @click="graphGoTo(2)"/>
          <path class="number" d="M826.676 48C825.083 48 823.632 47.7127 822.32 47.1381C821.032 46.5396 820.002 45.6418 819.229 44.4448C818.457 43.2477 818.047 41.7514 818 39.9558H821.688C821.735 41.2965 822.18 42.4337 823.023 43.3674C823.889 44.2772 825.107 44.732 826.676 44.732C828.174 44.732 829.322 44.3131 830.118 43.4751C830.914 42.6133 831.312 41.5479 831.312 40.279C831.312 38.7468 830.773 37.6096 829.696 36.8674C828.643 36.1252 827.343 35.7541 825.798 35.7541H823.971V32.5939H825.833C827.214 32.5939 828.326 32.2587 829.169 31.5884C830.036 30.918 830.469 29.9724 830.469 28.7514C830.469 27.7219 830.129 26.884 829.45 26.2376C828.795 25.5912 827.858 25.268 826.64 25.268C825.376 25.268 824.381 25.651 823.655 26.4171C822.929 27.1593 822.531 28.093 822.461 29.2182H818.773C818.82 27.7578 819.171 26.4889 819.826 25.4116C820.506 24.3343 821.43 23.4963 822.601 22.8978C823.772 22.2993 825.118 22 826.64 22C828.28 22 829.649 22.2993 830.75 22.8978C831.874 23.4724 832.717 24.2624 833.279 25.268C833.864 26.2495 834.157 27.3269 834.157 28.5C834.157 29.8407 833.794 31.0138 833.068 32.0193C832.342 33.0249 831.371 33.6952 830.153 34.0304C831.558 34.3416 832.717 35.0479 833.63 36.1492C834.543 37.2265 835 38.6271 835 40.3508C835 41.7394 834.684 43.0203 834.052 44.1934C833.419 45.3425 832.483 46.2643 831.242 46.9586C830.001 47.6529 828.479 48 826.676 48Z" fill="white" @click="graphGoTo(2)"/>

          <circle class="circle" :class="{ 'is-active': graphStep >= 1 }" cx="247" cy="302" r="35" fill="#6216EA" @click="graphGoTo(1)"/>
          <path class="number" d="M238 314V311.331C239.633 310.067 241.194 308.792 242.682 307.504C244.171 306.217 245.492 304.953 246.644 303.712C247.821 302.448 248.745 301.219 249.417 300.025C250.09 298.808 250.426 297.638 250.426 296.514C250.426 295.765 250.294 295.063 250.03 294.407C249.766 293.728 249.333 293.19 248.733 292.792C248.133 292.371 247.328 292.16 246.32 292.16C244.807 292.16 243.667 292.628 242.898 293.565C242.154 294.501 241.794 295.625 241.818 296.935H238.108C238.132 295.203 238.504 293.752 239.225 292.581C239.969 291.411 240.953 290.522 242.178 289.913C243.427 289.304 244.831 289 246.392 289C248.769 289 250.666 289.655 252.083 290.966C253.523 292.254 254.244 294.056 254.244 296.374C254.244 297.755 253.895 299.124 253.199 300.482C252.527 301.839 251.638 303.15 250.534 304.414C249.453 305.655 248.289 306.825 247.04 307.926C245.792 309.026 244.603 310.021 243.475 310.91H255V314H238Z" fill="white" @click="graphGoTo(1)"/>

          <circle class="circle" :class="{ 'is-active': graphStep >= 0 }" cx="247" cy="35" r="35" fill="#6216EA" @click="graphGoTo(0)"/>
          <path class="number" d="M245.054 49V29.1143L241 30.0057V27.3314L246.561 25H249V49H245.054Z" fill="white" @click="graphGoTo(0)"/>
      </svg>
    </div>

    <div class="data" data-scroll data-inview="fadeInUp" data-delay="300">
      <div class="data-content i1" :class="{ 'is-active': graphStep === 0 }">
          <h3 class="medium-title">{{ $t("Challenge & Dismantle") }}</h3>
          <p class="regular-text">{{ $t("Understand the current situation: keep the great, challenge the good and rethink the rest.") }}</p>
          <a href="#" class="cta">
            <p>{{ $t("Let's Connect") }}</p>
            <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
          </a>
      </div>
      <div class="data-content i2" :class="{ 'is-active': graphStep === 1 }">
          <h3 class="medium-title">{{ $t("Rethink the Status Quo") }}</h3>
          <p class="regular-text">{{ $t("Identify media opportunities, align with desired business outcomes and own your market's white space.") }}</p>
          <a href="#" class="cta">
            <p>{{ $t("Let's Connect") }}</p>
            <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
          </a>
      </div>
      <div class="data-content i3" :class="{ 'is-active': graphStep === 2 }">
          <h3 class="medium-title">{{ $t("Quick Wins") }}</h3>
          <p class="regular-text">{{ $t("Obtain quick outcomes with fresh, simple and innovative ideas.") }}</p>
          <a href="#" class="cta">
            <p>{{ $t("Let's Connect") }}</p>
            <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
          </a>
      </div>
      <div class="data-content i4" :class="{ 'is-active': graphStep === 3 }">
          <h3 class="medium-title">{{ $t("Continuous Improvement") }}</h3>
          <p class="regular-text">{{ $t("Adapt to the ever-changing media landscapes. Learn early, learn often and act on what really happens.") }}</p>
          <a href="#" class="cta">
            <p>{{ $t("Let's Connect") }}</p>
            <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
          </a>
      </div>
      <div class="data-content end" :class="{ 'is-active': graphStep === 4 }">
          <h3 class="medium-title">.</h3>
          <p class="regular-text">.</p>
          <a href="#" class="cta">
            <p>{{ $t("Let's Connect") }}</p>
            <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
          </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'methodology-graph',

  data() {
    return {
      graphStep: 0,
      graphStepImmediate: 0,
      // graphAutoplay: 1100,
      graphAutoplay: 4100,
      graphSteps: [
        {id: 'i1', draw: 1},
        {id: 'i2', draw: 0.7563},
        {id: 'i3', draw: 0.4763},
        {id: 'i4', draw: 0.24763},
        {id: 'end', draw: 0}
      ],
      graphInterval: null
    }
  },

  mounted() {
    if (document.querySelector('.desktop-svg-graph .graph-filler')) {
        document.querySelector('.desktop-svg-graph .graph-filler').style.strokeDashoffset = this.graphSteps[this.graphStep].draw
        document.querySelector('.mobile-svg-graph .graph-filler').style.strokeDashoffset = this.graphSteps[this.graphStep].draw
        this.graphStartAutoplay()
    }
  },

  methods: {
    graphStartAutoplay() {
        this.graphInterval = setInterval(this.graphGoNext, this.graphAutoplay)
    },
    graphGoTo(index) {
      this.graphStopAutoplay()
      this.graphGoNext(index)
    },
    graphStopAutoplay() {
        clearInterval(this.graphInterval)
        this.graphInterval = null
    },
    graphGoNext(index = 'none') {
      let next = null;

      if (index !== 'none') {
        next = index
      } else {
        next = this.graphStep + 1

        if (next >= (this.graphSteps.length - 1)) {
            this.graphReset(next)
            return
        }
      }

      this.graphStepImmediate = next
      document.querySelector('.desktop-svg-graph .graph-filler').style.strokeDashoffset = this.graphSteps[next].draw
      document.querySelector('.mobile-svg-graph .graph-filler').style.strokeDashoffset = this.graphSteps[next].draw

      setTimeout(() => {
        this.graphStep = next
      }, 1500)
    },
    graphReset(next) {
        // Go to end
        this.graphStepImmediate = next
        document.querySelector('.desktop-svg-graph .graph-filler').style.strokeDashoffset = this.graphSteps[next].draw
        document.querySelector('.mobile-svg-graph .graph-filler').style.strokeDashoffset = this.graphSteps[next].draw
        document.querySelector('#end-arrow').classList.add('is-active')
        document.querySelector('.end-arrow').classList.add('is-active')
        document.querySelector('#end-line').classList.add('is-active')
        document.querySelector('.end-line').classList.add('is-active')

        setTimeout(() => {
            document.querySelector('#end').classList.add('is-active')
            document.querySelector('.end').classList.add('is-active')
        }, 1500)

        // Hard reset
        setTimeout(() => {
            this.graphStepImmediate = 0
            this.graphStep = 0

            document.querySelector('#end-arrow').classList.remove('is-active')
            document.querySelector('.end-arrow').classList.remove('is-active')
            document.querySelector('#end-line').classList.remove('is-active')
            document.querySelector('.end-line').classList.remove('is-active')
            document.querySelector('#end').classList.remove('is-active')
            document.querySelector('.end').classList.remove('is-active')
            document.querySelector('.desktop-svg-graph .graph-filler').style.transition = 'none'
            document.querySelector('.mobile-svg-graph .graph-filler').style.transition = 'none'
            document.querySelector('.desktop-svg-graph .graph-filler').style.strokeDashoffset = 1
            document.querySelector('.mobile-svg-graph .graph-filler').style.strokeDashoffset = 1

            this.graphStopAutoplay()

            setTimeout(() => {
                document.querySelector('.desktop-svg-graph .graph-filler').style.transition = 'all 2s cubic-bezier(.215,.61,.355,1) 0s'
                document.querySelector('.mobile-svg-graph .graph-filler').style.transition = 'all 2s cubic-bezier(.215,.61,.355,1) 0s'
                setTimeout(() => {
                    this.graphStartAutoplay()
                }, 100)
            }, 100)
        }, 3500)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
