<template>
    <div id="top" class="page-wrapper">

        <section class="hero services" v-if="$route.meta.lang === 'en'">
            <div class="hero-wrap max-padding" data-scroll data-scroll-speed="-2">
                <h2 class="small-title">What We Do</h2>
                <h1 class="big-title">Ready to rethink your media?</h1>
            </div>
            <hero-svg-cut />
        </section>

        <section class="hero services" v-if="$route.meta.lang === 'fr'">
            <div class="hero-wrap max-padding" data-scroll data-scroll-speed="-2">
                <h2 class="small-title">Nos services</h2>
                <h1 class="big-title">Êtes-vous prêt à repenser votre média?</h1>
            </div>
            <hero-svg-cut />
        </section>

        <section class="services-list">
          <ul class="list">
            <li class="service">
              <a href="#services-innovation" data-scrollto="#services-innovation">
                <span class="number">
                  <span class="small-title">1</span>
                  <span class="small-title">/</span>
                </span>
                <p class="small-title">{{ $t("Innovation") }}</p>
              </a>
            </li>
            <li class="service">
              <a href="#services-strategy" data-scrollto="#services-strategy">
                <span class="number">
                  <span class="small-title">2</span>
                  <span class="small-title">/</span>
                </span>
                <p class="small-title">{{ $t("Strategy") }}</p>
              </a>
            </li>
            <li class="service">
              <a href="#services-performance" data-scrollto="#services-performance">
                <span class="number">
                  <span class="small-title">3</span>
                  <span class="small-title">/</span>
                </span>
                <p class="small-title">{{ $t("Performance") }}</p>
              </a>
            </li>
            <li class="service">
              <a href="#services-planning" data-scrollto="#services-planning">
                <span class="number">
                  <span class="small-title">4</span>
                  <span class="small-title">/</span>
                </span>
                <p class="small-title">{{ $t("Planning & Activation") }}</p>
              </a>
            </li>
            <li class="service">
              <a href="#services-data" data-scrollto="#services-data">
                <span class="number">
                  <span class="small-title">5</span>
                  <span class="small-title">/</span>
                </span>
                <p class="small-title">{{ $t("Data & Analytics") }}</p>
              </a>
            </li>
            <li class="service">
              <a href="#services-science" data-scrollto="#services-science">
                <span class="number">
                  <span class="small-title">6</span>
                  <span class="small-title">/</span>
                </span>
                <p class="small-title">{{ $t("Marketing Science") }}</p>
              </a>
            </li>
          </ul>
        </section>

        <logos-svg-cut />

        <section class="services-content">

          <div class="service-bloc left" id="services-innovation">
            <div class="img">
              <img src="@/assets/img/circle-rose.svg" class="top left circle" alt="Circle" data-scroll data-scroll-speed="0.5">
              <img src="@/assets/img/circle-blanc.svg" class="bottom right circle" alt="Circle" data-scroll data-scroll-speed="-0.5">
              <img src="@/assets/img/services-innovation.jpg" alt="Innovation">
            </div>
            <div class="data">
              <div class="title-wrap">
                <span class="number">
                  <span class="small-title">1</span>
                  <span class="small-title">/</span>
                  <span class="small-title">6</span>
                </span>
                <p class="small-title">{{ $t("Innovation") }}</p>
              </div>
              <p class="regular-text">{{ $t("service-innovation") }}</p>
            </div>
          </div>

          <div class="service-bloc right" id="services-strategy">
            <div class="img">
              <img src="@/assets/img/circle-bleu.svg" class="bottom left circle" alt="Circle" data-scroll data-scroll-speed="0.5">
              <img src="@/assets/img/circle-blanc.svg" class="top right circle" alt="Circle" data-scroll data-scroll-speed="-0.5">
              <img src="@/assets/img/services-strategy.jpg" alt="Strategy">
            </div>
            <div class="data">
              <div class="title-wrap">
                <span class="number">
                  <span class="small-title">2</span>
                  <span class="small-title">/</span>
                  <span class="small-title">6</span>
                </span>
                <p class="small-title">{{ $t("Strategy") }}</p>
              </div>
              <p class="regular-text">{{ $t("service-strategy") }}</p>
            </div>
          </div>

          <div class="service-bloc left" id="services-performance">
            <div class="img">
              <img src="@/assets/img/circle-rose.svg" class="top left circle" alt="Circle" data-scroll data-scroll-speed="0.5">
              <img src="@/assets/img/circle-blanc.svg" class="bottom right circle" alt="Circle" data-scroll data-scroll-speed="-0.5">
              <img src="@/assets/img/services-performance.jpg" alt="Performance">
            </div>
            <div class="data">
              <div class="title-wrap">
                <span class="number">
                  <span class="small-title">3</span>
                  <span class="small-title">/</span>
                  <span class="small-title">6</span>
                </span>
                <p class="small-title">{{ $t("Performance") }}</p>
              </div>
              <p class="regular-text">{{ $t("service-performance") }}</p>
            </div>
          </div>

          <div class="service-bloc right" id="services-planning">
            <div class="img">
              <img src="@/assets/img/circle-bleu.svg" class="bottom left circle" alt="Circle" data-scroll data-scroll-speed="0.5">
              <img src="@/assets/img/circle-blanc.svg" class="top right circle" alt="Circle" data-scroll data-scroll-speed="-0.5">
              <img src="@/assets/img/services-planning.jpg" alt="Planning & Activation">
            </div>
            <div class="data">
              <div class="title-wrap">
                <span class="number">
                  <span class="small-title">4</span>
                  <span class="small-title">/</span>
                  <span class="small-title">6</span>
                </span>
                <p class="small-title">{{ $t("Planning & Activation") }}</p>
              </div>
              <p class="regular-text">{{ $t("service-planning") }}</p>
            </div>
          </div>

          <div class="service-bloc left" id="services-data">
            <div class="img">
              <img src="@/assets/img/circle-rose.svg" class="top left circle" alt="Circle" data-scroll data-scroll-speed="0.5">
              <img src="@/assets/img/circle-blanc.svg" class="bottom right circle" alt="Circle" data-scroll data-scroll-speed="-0.5">
              <img src="@/assets/img/services-data.jpg" alt="Data & Analytics">
            </div>
            <div class="data">
              <div class="title-wrap">
                <span class="number">
                  <span class="small-title">5</span>
                  <span class="small-title">/</span>
                  <span class="small-title">6</span>
                </span>
                <p class="small-title">{{ $t("Data & Analytics") }}</p>
              </div>
              <p class="regular-text">{{ $t("service-data") }}</p>
            </div>
          </div>

          <div class="service-bloc right" id="services-science">
            <div class="img">
              <img src="@/assets/img/circle-bleu.svg" class="bottom left circle" alt="Circle" data-scroll data-scroll-speed="0.5">
              <img src="@/assets/img/circle-blanc.svg" class="top right circle" alt="Circle" data-scroll data-scroll-speed="-0.5">
              <img src="@/assets/img/services-science.jpg" alt="Marketing Science">
            </div>
            <div class="data">
              <div class="title-wrap">
                <span class="number">
                  <span class="small-title">6</span>
                  <span class="small-title">/</span>
                  <span class="small-title">6</span>
                </span>
                <p class="small-title">{{ $t("Marketing Science") }}</p>
              </div>
              <p class="regular-text">{{ $t("service-science") }}</p>
            </div>
          </div>

        </section>


    </div>
</template>

<script>
import HeroSvgCut from '@/components/svg-cut/HeroSvgCut.vue'
import LogosSvgCut from '@/components/svg-cut/LogosSvgCut.vue'

import { initLocoScroll } from '@/plugins/LocoScroll'
import { loadAssets } from '@/plugins/AssetsLoader'

export default {
    name: 'Home',

    components: {
        HeroSvgCut,
        LogosSvgCut,
    },

    data() {
      return {}
    },

    mounted() {
        const scroll = initLocoScroll()

        // Page load buffer
        setTimeout(() => {
            scroll.update()

            const defaultSrc = document.querySelector('[data-hero-svg-anim]').getAttribute('data-src')
            const tt = new Date().valueOf()
            document.querySelector('[data-hero-svg-anim]').setAttribute('src', `${defaultSrc}?tt=${tt}`)
            document.querySelector('[data-hero-svg-anim]').classList.add('is-active')

            setTimeout(() => {
                scroll.update()
            }, 3155)
        }, 755)

        // ReUpdate LocoScroll after assets load
        loadAssets('[data-scroll-container]', () => {
            scroll.update()
        })
    },
}
</script>
