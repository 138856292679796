<template>
    <div id="top" class="page-wrapper">
        
        
        <section class="hero work" v-if="$route.meta.lang === 'en'">
            <div class="hero-wrap max-padding" data-scroll data-scroll-speed="-2">
                <h2 class="small-title">Merck</h2>
                <h1 class="big-title">Gardasil-9: "I Got It" campaign</h1>
            </div>
            <hero-svg-cut-light />
        </section>

        <section class="hero work" v-if="$route.meta.lang === 'fr'">
            <div class="hero-wrap max-padding" data-scroll data-scroll-speed="-2">
                <h2 class="small-title">Merck</h2>
                <h1 class="big-title">Gardasil-9: campagne «Je l'ai reçu»</h1>
            </div>
            <hero-svg-cut-light />
        </section>



        <section class="work-content" v-if="$route.meta.lang === 'en'">
          <div class="img-wrap">
            <img src="@/assets/img/gardasil-hero.jpg" alt="Gardasil">
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Context</h3>
            </div>
            <div class="right">
              <p class="regular-text">Current vaccination rates are too low for all age cohorts.<br><br>Very few Canadian women are vaccinated with at least 1 dose of an HPV vaccine and the percentage is even lower among men. Our goal is to double the amount of vaccinated adults by the end of 2022.</p>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Goals</h3>
            </div>
            <div class="right">
              <ul class="list-wrap">
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Increase awareness, engagement and conversion to G9 of all eligible Canadians (men, women, students)</p>
                </li>
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Create urgency for health care professionals to make strong G9 recommendations as a standard of care at trigger visits</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Strategy</h3>
            </div>
            <div class="right">
              <p class="regular-text">We were able to build an optimal reach and frequency approach with our primary targets using a 360 media mix. The market was communicated broadely using messages that spoke to both men and women.  As we drove users down the funnel we focused on our personnas by targeting them through video tactics (TVs, Online Video and Pocket Screens).  We then drove consideration through our display banners in high contextual environments and finally drove conversions through our social media tactics.  </p>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Results</h3>
            </div>
            <div class="right">
              <div class="stats-wrap">
                <div class="stat">
                  <p class="number">54%</p>
                  <p class="regular-text">lift in unique visitors on website following our campaign</p>
                </div>
                <div class="stat">
                  <p class="number">32K</p>
                  <p class="regular-text">unique visitors on the clinic locator in one month</p>
                </div>
                <div class="stat">
                  <p class="number">11.5%</p>
                  <p class="regular-text">of online users were converted in one month after seeing our display ads</p>
                </div>
                <div class="stat">
                  <p class="number">58%</p>
                  <p class="regular-text">of users are considered qualified sessions</p>
                </div>
              </div>
            </div>
          </div>

          <div class="media-wrap">
            <div class="video-player" data-item="video">
                <video autoplay muted loop playsinline class="video-1">
                    <source src="@/assets/video/gardasil-video.mp4" type="video/mp4">
                </video>
                <img src="@/assets/img/tv-frame.png" class="video" alt="Connected TV">
            </div>
            <div class="photos-wrap full">
              <img src="@/assets/img/gardasil-img-1.jpg" alt="Gardasil">
            </div>
            <div class="photos-wrap half">
              <img src="@/assets/img/gardasil-img-2.jpg" alt="Gardasil">
              <img src="@/assets/img/gardasil-img-3.jpg" alt="Gardasil">
            </div>
          </div>

          <work-svg-cut />
        </section>

        <section class="work-content" v-if="$route.meta.lang === 'fr'">
          <div class="img-wrap">
            <img src="@/assets/img/gardasil-hero.jpg" alt="Gardasil">
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Contexte</h3>
            </div>
            <div class="right">
              <p class="regular-text">Les taux de vaccination actuels sont trop bas pour toutes les cohortes d'âge.<br><br>Seulement 11% des femmes canadiennes  sont vaccinées avec au moins 1 dose du vaccin G9 et le pourcentage est encore plus bas pour les hommes. L'objectif est de de doubler d'ici la fin de 2022.</p>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Objectifs</h3>
            </div>
            <div class="right">
              <ul class="list-wrap">
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Susciter l'urgence pour les professionnel de la santé de faire de fortes recommandations G9 lors des visites de déclenchement comme une norme de soins lors des visites de déclenchement</p>
                </li>
                <li>
                  <img src="@/assets/img/arrow-right-pink.svg" alt="Arrow">
                  <p class="regular-text">Accroître la sensibilisation, l'engagement et la conversion au G9 de tous les Canadiens éligibles (hommes, femmes, étudiants)</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Stratégie</h3>
            </div>
            <div class="right">
              <p class="regular-text">Il nous a été possible de bâtir une portée et une fréquence optimale auprès de nos cibles primaires à l'aide d'une stratégie média 360.  Une fois le message communiqué aux cibles, nous dementellons celle-ci afin de joindre plus précisément notre cœur de cible, correspondant à l’une des huit personas, présentés par le client, afin d’obtenir un contrôle sur notre portée et la fréquence du message. Un rappel est ensuite effectué afin de s’assurer que Gardasil 9 soit considéré à titre de solution, mais surtout que notre coeur de cible converge avec le produit </p>
            </div>
          </div>
          <div class="text-wrap">
            <div class="left">
              <h3 class="medium-title">Résultats</h3>
            </div>
            <div class="right">
              <div class="stats-wrap">
                <div class="stat">
                  <p class="number">54%</p>
                  <p class="regular-text">augmentation du nombre de visiteurs uniques sur le site web suite à notre campagne</p>
                </div>
                <div class="stat">
                  <p class="number">32 K</p>
                  <p class="regular-text">visiteurs uniques sur le localisateur de cliniques en un mois</p>
                </div>
                <div class="stat">
                  <p class="number">11,50 %</p>
                  <p class="regular-text">des utilisateurs en ligne ont été convertis en un mois après avoir vu nos annonces publicitaires</p>
                </div>
                <div class="stat">
                  <p class="number">58 %</p>
                  <p class="regular-text">des utilisateurs sont considérés comme des sessions qualifiées, ce qui signifie que nous ciblons les bonnes personnes.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="media-wrap">
            <div class="video-player" data-item="video">
                <video autoplay muted loop playsinline class="video-1">
                    <source src="@/assets/video/gardasil-video.mp4" type="video/mp4">
                </video>
                <img src="@/assets/img/tv-frame.png" class="video" alt="Connected TV">
            </div>
            <div class="photos-wrap full">
              <img src="@/assets/img/gardasil-img-1.jpg" alt="Gardasil">
            </div>
            <div class="photos-wrap half">
              <img src="@/assets/img/gardasil-img-2.jpg" alt="Gardasil">
              <img src="@/assets/img/gardasil-img-3.jpg" alt="Gardasil">
            </div>
          </div>

          <work-svg-cut />
        </section>


        <section class="other-work" v-if="$route.meta.lang === 'en'">
          <h4 class="small-title">Other work</h4>
          <div class="blocs">
            <a href="/instacart" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="300">
              <div class="img">
                <img src="@/assets/img/instacart-logo.svg" class="logo" alt="Instacart logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/instacart-img.jpg" class="cover" alt="Instacart">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("Instacart") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
            <a href="/notco" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="400">
              <div class="img">
                <img src="@/assets/img/logos/not-co.png" class="logo" alt="NotCo logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/work-3.jpg" class="cover" alt="Media Innovation">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("NotCo") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
          </div>
        </section>

        <section class="other-work" v-if="$route.meta.lang === 'fr'">
          <h4 class="small-title">Autres projets</h4>
          <div class="blocs">
            <a href="/fr/instacart" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="300">
              <div class="img">
                <img src="@/assets/img/instacart-logo.svg" class="logo" alt="Instacart logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/instacart-img.jpg" class="cover" alt="Instacart">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("Instacart") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
            <a href="/fr/notco" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="400">
              <div class="img">
                <img src="@/assets/img/logos/not-co.png" class="logo" alt="NotCo logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/work-3.jpg" class="cover" alt="Media Innovation">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("NotCo") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
          </div>
        </section>

    </div>
</template>

<script>


import HeroSvgCutLight from '@/components/svg-cut/HeroSvgCutLight.vue'
import WorkSvgCut from '@/components/svg-cut/WorkSvgCut.vue'

import { initLocoScroll } from '@/plugins/LocoScroll'
import { loadAssets } from '@/plugins/AssetsLoader'

export default {
    name: 'Work',

    components: {
        HeroSvgCutLight,
        WorkSvgCut
    },

    data() {
      return {}
    },

    mounted() {
        const scroll = initLocoScroll()

        // Page load buffer
        setTimeout(() => {
            scroll.update()

            setTimeout(() => {
                scroll.update()
            }, 3155)
        }, 755)

        // ReUpdate LocoScroll after assets load
        loadAssets('[data-scroll-container]', () => {
            scroll.update()
        })
    },
}
</script>
