<template>
    <section id="expertises" class="media-expertises">
      <div class="top max-padding" data-scroll data-inview="fadeInUp" data-delay="100">
        <h3 class="small-title">{{ $t("What We Do") }}</h3>

        <div class="arrows">
          <div class="arrow left" @click="goTo('prev')" :class="{ 'is-disabled': currentIndex <= 1 }">
            <img src="@/assets/img/arrow-right.svg" alt="Arrow right">
          </div>
          <div class="arrow" @click="goTo('next')" :class="{ 'is-disabled': currentIndex >= totalIndex }">
            <img src="@/assets/img/arrow-right.svg" alt="Arrow right">
          </div>

        </div>
      </div>

      <div id="drag-slider" class="slides-wrapper">
        <div id="drag-slider-inner" class="drag-slider-inner">
            <div class="slide">
                <div class="data" data-item="data">
                  <div class="step" data-scroll data-inview="slideLeft" data-delay="100"><p>1<span>/</span>5</p></div>
                  <h2 class="medium-title" data-scroll data-inview="slideLeft" data-delay="200">{{ $t("Strategic Planning") }}</h2>
                  <p class="regular-text" data-scroll data-inview="slideLeft" data-delay="300">{{ $t("We’ve developed a unique cross communication channel planning mething that increase brand recall.") }}</p>
                  <a href="#contact" data-scrollto="#contact" class="cta" data-scroll data-inview="slideLeft" data-delay="400">
                    <p>{{ $t("Let's Connect") }}</p>
                    <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
                  </a>
                </div>
                <div class="media">
                  <div class="video-wrap" data-scroll data-inview="slideLeft" data-delay="500">
                    <div class="video-player" data-item="video">
                        <img src="@/assets/img/footer-tv.jpg" class="footer-tv" alt="Connected TV">
                        <video autoplay muted loop playsinline class="video-1">
                            <source src="@/assets/video/tv-fr.mp4" type="video/mp4" v-if="$route.meta.lang === 'fr'">
                            <source src="@/assets/video/tv-en.mp4" type="video/mp4" v-if="$route.meta.lang === 'en'">
                        </video>
                        <img src="@/assets/img/tv-frame.png" class="video" alt="Connected TV">
                    </div>
                  </div>
                  <div class="img-wrap" data-item="img">
                    <img src="@/assets/img/media-expertise-1.jpg" alt="Connected TV" data-scroll data-inview="revealLeft" data-delay="200">
                  </div>
                </div>
            </div>
            <div class="slide">
                <div class="data" data-item="data">
                  <div class="step"><p>2<span>/</span>5</p></div>
                  <h2 class="medium-title">{{ $t("Out-of-Home") }}</h2>
                  <p class="regular-text">{{ $t("OOH advertising is a key compenent of the media mix as it allows us to target on-the-go audiences with precision.") }}</p>
                  <a href="#contact" data-scrollto="#contact" class="cta">
                    <p>{{ $t("Let's Connect") }}</p>
                    <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
                  </a>
                </div>
                <div class="media">
                  <div class="video-wrap vertical-format">
                    <div class="video-player" data-item="video">
                        <video autoplay muted loop playsinline class="video-2">
                            <source src="@/assets/video/dooh-fr.mp4" type="video/mp4" v-if="$route.meta.lang === 'fr'">
                            <source src="@/assets/video/dooh-en.mp4" type="video/mp4" v-if="$route.meta.lang === 'en'">
                        </video>
                        <img src="@/assets/img/dooh-2.png" class="video vertical-format" alt="">
                    </div>
                  </div>
                  <div class="img-wrap" data-item="img">
                    <img src="@/assets/img/media-expertise-2.jpg" alt="Connected TV">
                  </div>
                </div>
            </div>
            <div class="slide">
                <div class="data" data-item="data">
                  <div class="step"><p>3<span>/</span>5</p></div>
                  <h2 class="medium-title">{{ $t("Social Media") }}</h2>
                  <p class="regular-text">{{ $t("We take an agnostic approach to reaching your audience throughout the digital lanscape.") }}</p>
                  <a href="#contact" data-scrollto="#contact" class="cta">
                    <p>{{ $t("Let's Connect") }}</p>
                    <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
                  </a>
                </div>
                <div class="media">
                  <div class="video-wrap vertical-format" data-scroll data-inview="slideLeft" data-delay="500">
                    <div class="video-player" data-item="video">
                        <img src="@/assets/img/iphone-frame.png" class="video vertical-format-small" alt="">
                        <video autoplay muted loop playsinline class="video-3">
                          <source src="@/assets/video/phone.mp4" type="video/mp4">
                        </video>
                        
                    </div>
                  </div>
                  <div class="img-wrap" data-item="img">
                    <img src="@/assets/img/media-expertise-3.jpg" alt="Connected TV" data-scroll data-inview="revealLeft" data-delay="200">
                  </div>
                </div>
            </div>
            <div class="slide">
                <div class="data" data-item="data">
                  <div class="step"><p>4<span>/</span>5</p></div>
                  <h2 class="medium-title">{{ $t("The Power of the Mid-Funnel") }}</h2>
                  <p class="regular-text">{{ $t("We amplify the mid-funnel to limit upper funnel spills and increase lower funnel performance.") }}</p>
                  <a href="#contact" data-scrollto="#contact" class="cta">
                    <p>{{ $t("Let's Connect") }}</p>
                    <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
                  </a>
                </div>
                <div class="media">
                  <div class="video-wrap" data-scroll data-inview="slideLeft" data-delay="500">
                    <img src="@/assets/img/funnel-fr.png" class="video smaller" alt="Connected TV" data-item="video" v-if="$route.meta.lang === 'fr'">
                    <img src="@/assets/img/funnel-en.png" class="video smaller" alt="Connected TV" data-item="video" v-if="$route.meta.lang === 'en'">
                  </div>
                  <div class="img-wrap" data-item="img">
                    <img src="@/assets/img/media-expertise-4.jpg" alt="Connected TV" data-scroll data-inview="revealLeft" data-delay="200">
                  </div>
                </div>
            </div>
            <div class="slide">
                <div class="data" data-item="data">
                  <div class="step"><p>5<span>/</span>5</p></div>
                  <h2 class="medium-title">{{ $t("Live Dashboards") }}</h2>
                  <p class="regular-text">{{ $t("Our clients are setup with dashboards to monitor results and make better decisions in real time.") }}</p>
                  <a href="#contact" data-scrollto="#contact" class="cta">
                    <p>{{ $t("Let's Connect") }}</p>
                    <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
                  </a>
                </div>
                <div class="media">
                  <div class="video-wrap" data-scroll data-inview="slideLeft" data-delay="500">
                    <img src="@/assets/img/macbook.png" class="video smaller top" alt="Connected TV" data-item="video">
                  </div>
                  <div class="img-wrap" data-item="img">
                    <img src="@/assets/img/media-expertise-5.jpg" alt="Connected TV" data-scroll data-inview="revealLeft" data-delay="200">
                  </div>
                </div>
            </div>
        </div>
      </div>
    </section>
</template>

<script>
import { mapState } from 'vuex'

import { gsap } from 'gsap'
import Draggable from 'gsap/Draggable'

gsap.registerPlugin(Draggable)

export default {
    name: 'media-expertises-slider',

    data() {
      return {
        canSlide: true,
        currentIndex: 1,
        totalIndex: 5
      }
    },

    computed: {
        ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
    },

    methods: {
        goTo(pos = 'next') {

            if (!this.canSlide) {
                return
            }

            if (pos === 'next' && this.currentIndex === this.totalIndex) {
                return
            }

            if (pos === 'prev' && this.currentIndex === 1) {
                return
            }

            this.canSlide = false

            let slideValue = '-=140vw'

            if (pos === 'prev') {
                slideValue = '+=140vw'
                this.currentIndex--
            } else {
                this.currentIndex++
            }

            const slides = [].slice.call(document.querySelectorAll('.drag-slider-inner .slide'))

            slides.forEach((slide) => {
                const dataEl = slide.querySelector('[data-item="data"]')
                const videoEl = slide.querySelector('[data-item="video"]')
                const imgEl = slide.querySelector('[data-item="img"]')

                gsap.to(dataEl, {
                    duration: 1,
                    delay: 0,
                    x: slideValue,
                    ease: 'power1.out'
                })

                gsap.to(imgEl, {
                    duration: 1.50,
                    delay: 0,
                    x: slideValue,
                    ease: 'power3.out'
                })

                gsap.to(videoEl, {
                    duration: 1.65,
                    delay: 0,
                    x: slideValue,
                    ease: 'power3.out',
                    onComplete: () => {
                        this.canSlide = true
                    }
                })
            })
        }
    },

    mounted() {
        const vComponent = this

        Draggable.create('#drag-slider-inner', {
            type: 'x',
            bounds: document.getElementById('drag-slider'),
            throwProps: true,
            edgeResistance: 0.75,
            lockAxis: true,
            minimumMovement: 6,
            onClick: () => {
                // console.log('clicked')
            },
            onDrag: function() {
                // TweenLite.to(document.getElementById('drag-slider-inner'), 0.8, {
                //     x: this.x - this.offset,
                // })
            },
            onDragEnd: function() {
                // console.log('drag ended')
                gsap.to('#drag-slider-inner', {
                    duration: 0.5,
                    delay: 0,
                    x: 0,
                    ease: 'power1.out'
                })

                if (this.getDirection('start') === 'right') {
                    vComponent.goTo('prev')
                } else {
                    vComponent.goTo('next')
                }
            }
        })
    },
}
</script>

<style lang="scss" scoped>

</style>


