/**
 * Locomotive Scroll activation + extras
 *
 */
import LocomotiveScroll from 'locomotive-scroll'

let scroll = null

export const initLocoScroll = () => {
    // Only update if already inited
    if (document.querySelector('html').classList.contains('has-scroll-init')) {
        scroll.update()

        setTimeout(() => {
            scroll.update()
            const scrollerDataAttr = 'data-scrollto'
            ;[].slice
                .call(document.querySelectorAll('[' + scrollerDataAttr + ']'))
                .forEach(function (elem) {
                    elem.addEventListener('click', e => {
                        e.preventDefault()
                        const target = elem.getAttribute('href')
                        scroll.scrollTo(target)
                    })
                })
        }, 755)
        return scroll
    }

    scroll = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        offset: [0, 0],
        repeat: false,
        smoothMobile: true,
        tablet: { 'smooth': true },
        smartphone: { 'smooth': true },
        getDirection: true
    })

    setTimeout(() => {
        scroll.update()
    }, 500);

    let previousCheck = 0

    setTimeout(() => {
        scroll.update()

        scroll.on('scroll', instance => {

            const check = (instance.delta && instance.delta.y) ? instance.delta.y : window.scrollY

            if (instance.direction === 'up') {
                if (!previousCheck || (previousCheck - check) >= 50) {
                    document.documentElement.setAttribute('data-direction', 'up')
                }
            } else {
                document.documentElement.setAttribute('data-direction', instance.direction)
            }

            previousCheck = check

            // console.log({ check })

            if (check >= 50) {
                document.querySelector('.navbar').classList.add('sticky')
            } else {
                document.querySelector('.navbar').classList.remove('sticky')
            }
        })

        // Smooth Scroll to href component
        if (document.querySelector('[data-scrollto]')) {
            const scrollerDataAttr = 'data-scrollto'
            ;[].slice
                .call(document.querySelectorAll('[' + scrollerDataAttr + ']'))
                .forEach(function (elem) {
                    elem.addEventListener('click', e => {
                        e.preventDefault()
                        const target = elem.getAttribute('href')
                        scroll.scrollTo(target)
                    })
                })
        }

        setTimeout(() => {
            scroll.update()
        }, 755)
    }, 555)

    return scroll
}

export const locoScroll = scroll
