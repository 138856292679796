export const loadAssets = (selector = '[data-scroll-container]', trigger) => {
    const allImgs = [].slice.call(document.querySelectorAll(`${selector} img`))
    const nbElements = allImgs.length
    let nbElementsLoaded = 0

    allImgs.forEach((elem) => {
        elem.onload = () => {
            nbElementsLoaded++

            if (nbElementsLoaded === nbElements) {
                trigger()
            }
        }
    })
}
